<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>

    <div class="nf-tabs">
      <div class="nf-tabs-nav">
        <router-link
          v-for="(nav, index) in tabsNav"
          :key="index"
          class="nf-tabs-nav-label"
          :class="{'is-active': activeNav === index}"
          :to="`${nav.url}`"
        >
          <i v-if="nav.name === '审批管理'" class="icon icon-approval"></i>
          <i v-if="nav.name === '我的申请'" class="icon icon-application"></i>
          <i v-if="nav.name === '统计报表'" class="icon icon-excel"></i>
          <span>{{nav.name}}</span>
        </router-link>
      </div>
      <div class="nf-tabs-content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import nfBreadcrumb from "@/component/breadcrumb/breadcrumb";

export default {
  components: {
    nfBreadcrumb
  },

  data() {
    return {
      activeNav: 0,
      tabsNav: [
        {
          name: "审批管理",
          url: "approvalManagement"
        },
        {
          name: "我的申请",
          url: "myApplication"
        }
        // ,
        // {
        //   name: "统计报表",
        //   url: "dutyStatistics"
        // }
      ],
      breadList: [
        {
          name: "业务协同"
        },
        {
          name: "勤务管理"
        }
      ]
    };
  },

  created() {
    const path = this.$route.path;
    if (path === "/dutyManagement/approvalManagement") {
      this.activeNav = 0;
    } else if (path === "/dutyManagement/myApplication") {
      this.activeNav = 1;
    } else if (path === "/dutyManagement/dutyStatistics") {
      this.activeNav = 2;
    }
    this.getDeptId();
    this.getDepartment();
  },
  methods: {
    // 获取部门id
    async getDeptId() {
      const data = await this.$fetchData.fetchGet({}, '/api/attendance/v1/approval/department');
      if (data.code === '200') {
        if (data.result && data.result.length > 0) {
          if (data.result.length > 0) {
            this.tabsNav.push({
              name: "统计报表",
              url: "dutyStatistics"
            });
          }
        }
      } else {
        this.$message({ type: 'error', message: data.message });
      }
    },
    async getDepartment() {
      const res = await this.$fetchData.fetchGet({}, this.$fetchApi.department.api, 'json'); // 查看是否为局办公室


      if (res.code === '200') {
        const result = await this.$fetchData.fetchGet({}, this.$fetchApi.isNBleader.api + res.result[0].id, 'json'); // 查看是否为组织领导
        localStorage.setItem('isAllLeader', result.result);
        console.log(result.result, '是否为组织领导');
        if (Array.isArray(res.result) && res.result.length > 0) {
          const arr = [];
          res.result.map((item) => {
            arr.push(item.name);
          });
          console.log(arr, '所处部门');
          if (result.result === true){
            this.tabsNav.map((item, index) => {
              if (item.name === '我的申请') {
                this.tabsNav.splice(index, 1);
              }
            });
          }
        } else {
          localStorage.setItem('idLeaderNb', false);
        }

      }

    }
  }
};
</script>
<style lang="less" scoped>
.nf-tabs {
  max-width: 1200px;
  min-height: 600px;
  display: flex;
  margin: 0 auto;

  .icon {
    display: inline-block;
    vertical-align: middle;
    width: 30px;
    height: 30px;
    background: none no-repeat center;
    background-size: 100%;
    margin-right: 4px;

    &-approval {
      background-image: url("../../assets/images/dutyManagement/icon_approval.png");
    }

    &-application {
      background-image: url("../../assets/images/dutyManagement/icon_application.png");
    }

    &-excel{
      background-image: url("../../assets/images/dutyManagement/icon_excel.png");
    }
  }

  &-nav {
    min-width: 170px;
    max-width: 170px;
    margin-right: 10px;
    background: #ffffff;
    box-shadow: 2px 0px 3px 0px rgba(38, 90, 225, 0.15);

    &-label {
      display: block;
      height: 60px;
      line-height: 60px;
      padding: 0 48px 0 24px;
      font-weight: 700;
      color: #3380fe;

      &.is-active {
        background: url("../../assets/images/officialDocument/pic_tab_nav_bg.png")
          center no-repeat;
        background-size: 100%;
      }
    }
  }

  &-content {
    width: 100%;
  }
}
</style>
